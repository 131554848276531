export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは6月より%d点上がりました。変化が見られたのはどの項目ですか？<br>\nこの夏、アイスやジュースをどのくらい食べていますか？冷たい食べ物は甘味を感じづらく、通常より砂糖が多く含まれます。また暑さから免疫が落ちやすく、虫歯になりやすい状態です。毎日の歯磨きはもちろん、<a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">定期的に歯科検診を受けて</a>綺麗な口内を維持しましょう。\n\n",
      "down": "生活習慣スコアは6月より%d点下がりました。変化が見られたのはどの項目ですか？<br>\nこの夏、アイスやジュースをどのくらい食べていますか？冷たい食べ物は甘味を感じづらく、通常より砂糖が多く含まれます。また暑さから免疫が落ちやすく、虫歯になりやすい状態です。毎日の歯磨きはもちろん、<a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">定期的に歯科検診を受けて</a>綺麗な口内を維持しましょう。\n\n",
      "equal100": "生活習慣スコアは6月に引き続き%満点%です。素晴らしい！<br>\nこの夏、アイスやジュースをどのくらい食べていますか？冷たい食べ物は甘味を感じづらく、通常より砂糖が多く含まれます。また暑さから免疫が落ちやすく、虫歯になりやすい状態です。毎日の歯磨きはもちろん、<a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">定期的に歯科検診を受けて</a>綺麗な口内を維持しましょう。\n\n",
      "equal": "生活習慣スコアは6月と同じ点数です。<br>\nこの夏、アイスやジュースをどのくらい食べていますか？冷たい食べ物は甘味を感じづらく、通常より砂糖が多く含まれます。また暑さから免疫が落ちやすく、虫歯になりやすい状態です。毎日の歯磨きはもちろん、<a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">定期的に歯科検診を受けて</a>綺麗な口内を維持しましょう。\n\n"
    },
    "steps": {
      "up": "歩数スコアは6月より%d点上がりました。暑い日が続いていますが素晴らしいです！<br>\nWalk チーム対抗戦 2024 2nd Roundのエントリーが8/20より開始されます。1st Roundより良い記録を目指し、歩く時間を増やしていきましょう！\n\n",
      "down": "歩数スコアは6月より%d点下がりました。<br>\nWalk チーム対抗戦 2024 2nd Roundのエントリーが8/20より開始されます。1st Roundより良い記録を目指し、歩く時間を増やしていきましょう！\n\n",
      "equal": "歩数スコアは6月と同じ点数です。<br>\nWalk チーム対抗戦 2024 2nd Roundのエントリーが8/20より開始されます。1st Roundより良い記録を目指し、歩く時間を増やしていきましょう！\n\n"
    },
    "exercise": {
      "up": "運動のスコアは6月より%d点上がりました。<br>\n危険な暑さで屋外での運動が難しい時は、室内で体を動かす時間を増やすのはいかがでしょうか？おすすめの運動は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">こちら</a>から。\n\n",
      "down": "運動のスコアは6月より%d点下がりました。先月の自分を振り返ってみましょう。<br>\n危険な暑さで屋外での運動が難しい時は、室内で体を動かす時間を増やすのはいかがでしょうか？おすすめの運動は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">こちら</a>から。\n\n",
      "equal": "運動のスコアは6月と同じ点数です。<br>\n危険な暑さで屋外での運動が難しい時は、室内で体を動かす時間を増やすのはいかがでしょうか？おすすめの運動は<a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">こちら</a>から。\n\n"
    },
    "meal": {
      "up": "食事のスコアは6月より%d点上がりました。<br>\n豚肉や玄米、大豆などに多く含まれるビタミンB1は疲労回復におすすめの栄養素です。さらに、玉ねぎやニンニクに多いアリシンと合わせると効果が高まります！\n\n",
      "down": "食事のスコアは6月より%d点下がりました。<br>\n豚肉や玄米、大豆などに多く含まれるビタミンB1は疲労回復におすすめの栄養素です。さらに、玉ねぎやニンニクに多いアリシンと合わせると効果が高まります！\n\n",
      "equal": "食事のスコアは6月と同じ点数です。<br>\n豚肉や玄米、大豆などに多く含まれるビタミンB1は疲労回復におすすめの栄養素です。さらに、玉ねぎやニンニクに多いアリシンと合わせると効果が高まります！\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは6月より%d点上がりました。<br>\nアルコールは一種の麻酔薬。一時的な作用で寝つきが良くなっても一定時間経つと覚醒してしまい、結果として睡眠の質は下がります。夜は寝る2〜3時間前には飲み終えるのが、おすすめです。\n\n",
      "down": "飲酒のスコアは6月より%d点下がりました。飲み過ぎには気をつけましょう。<br>\nアルコールは一種の麻酔薬。一時的な作用で寝つきが良くなっても一定時間経つと覚醒してしまい、結果として睡眠の質は下がります。夜は寝る2〜3時間前には飲み終えるのが、おすすめです。\n\n",
      "equal": "飲酒のスコアは6月と同じ点数です。<br>\nアルコールは一種の麻酔薬。一時的な作用で寝つきが良くなっても一定時間経つと覚醒してしまい、結果として睡眠の質は下がります。夜は寝る2〜3時間前には飲み終えるのが、おすすめです。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは6月より%d点上がりました。<br>\n寝苦しい日々が続きますが、寝る時の服装は意識していますか？綿や麻など通気性が良く動きやすい素材の服を着ることで、快眠効果が得られます。\n\n",
      "down": "睡眠のスコアは6月より%d点下がりました。<br>\n寝苦しい日々が続きますが、寝る時の服装は意識していますか？綿や麻など通気性が良く動きやすい素材の服を着ることで、快眠効果が得られます。\n\n",
      "equal": "睡眠のスコアは6月と同じ点数です。<br>\n寝苦しい日々が続きますが、寝る時の服装は意識していますか？綿や麻など通気性が良く動きやすい素材の服を着ることで、快眠効果が得られます。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは6月より%d点上がりました。<br>\n屋内外の気温差が5度以上あると、体への負担がかかりやすくなると言われます。エアコンの設定温度はこまめにチェックしましょう。\n\n",
      "down": "ストレスのスコアは6月より%d点下がりました。<br>\n屋内外の気温差が5度以上あると、体への負担がかかりやすくなると言われます。エアコンの設定温度はこまめにチェックしましょう。\n\n",
      "equal": "ストレスのスコアは6月と同じ点数です。<br>\n屋内外の気温差が5度以上あると、体への負担がかかりやすくなると言われます。エアコンの設定温度はこまめにチェックしましょう。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up %d points since June.<br>\nHow much ice cream and soda are you having this summer? It's harder for cold foods to taste sweet, so they contain more sugar than usual. In addition, the heat can easily weaken your immune system, making you more susceptible to tooth decay. So, besides brushing your teeth every day, also <a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">have regular checkups</a> to keep your mouth clean.\n\n",
      "down": "Your lifestyle score has gone down %d points since June. <br>\nHow much ice cream and soda are you having this summer? It's harder for cold foods to taste sweet, so they contain more sugar than usual. In addition, the heat can easily weaken your immune system, making you more susceptible to tooth decay. So, besides brushing your teeth every day, also <a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">have regular checkups</a> to keep your mouth clean.\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in June. <br>\nHow much ice cream and soda are you having this summer? It's harder for cold foods to taste sweet, so they contain more sugar than usual. In addition, the heat can easily weaken your immune system, making you more susceptible to tooth decay. So, besides brushing your teeth every day, also <a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">have regular checkups</a> to keep your mouth clean.\n\n",
      "equal": "Your lifestyle score is the same as it was in June. <br>\nHow much ice cream and soda are you having this summer? It's harder for cold foods to taste sweet, so they contain more sugar than usual. In addition, the heat can easily weaken your immune system, making you more susceptible to tooth decay. So, besides brushing your teeth every day, also <a href=\"https://go.andwell.jp/library/tag/99ed922d-5e2f-11ec-9728-06d158a16ffc\">have regular checkups</a> to keep your mouth clean.\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since June.<br>\nEntries for the Team Walk Competition 2024 2nd Round will open on August 20, and we encourage you to increase your walking time in order to achieve a better record than in the 1st Round!\n\n",
      "down": "Your steps score has gone down %d points since June. <br>\nEntries for the Team Walk Competition 2024 2nd Round will open on August 20, and we encourage you to increase your walking time in order to achieve a better record than in the 1st Round!\n\n",
      "equal": "Your steps score is the same as it was in June. <br>\nEntries for the Team Walk Competition 2024 2nd Round will open on August 20, and we encourage you to increase your walking time in order to achieve a better record than in the 1st Round!\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since June.<br>\nWhen dangerously hot weather makes it difficult to exercise outdoors, how about spending more time being physically active indoors? Click <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">here</a> for some recommended exercises.\n\n",
      "down": "Your exercise score has gone down %d points since June.<br>\nWhen dangerously hot weather makes it difficult to exercise outdoors, how about spending more time being physically active indoors? Click <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">here</a> for some recommended exercises.\n\n",
      "equal": "Your exercise score is the same as it was in June. <br>\nWhen dangerously hot weather makes it difficult to exercise outdoors, how about spending more time being physically active indoors? Click <a href=\"https://go.andwell.jp/library/tag/aa3c3c43-af3e-11ec-b99b-0af363eb5062\">here</a> for some recommended exercises.\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since June.<br>\nVitamin B1, which is found in pork, brown rice, and soybeans, is a recommended nutrient for relieving fatigue. Furthermore, when combined with allicin, which is abundant in onions and garlic, its effects are enhanced!\n\n",
      "down": "Your food score has gone down %d points since June.<br>\nVitamin B1, which is found in pork, brown rice, and soybeans, is a recommended nutrient for relieving fatigue. Furthermore, when combined with allicin, which is abundant in onions and garlic, its effects are enhanced!\n\n",
      "equal": "Your food score is the same as it was in June. <br>\nVitamin B1, which is found in pork, brown rice, and soybeans, is a recommended nutrient for relieving fatigue. Furthermore, when combined with allicin, which is abundant in onions and garlic, its effects are enhanced!\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since June.<br>\nAlcohol is a kind of anesthetic. Even if its temporary effects help you fall asleep, you'll wake up after a certain period of time, resulting in a lower quality of sleep. At night, the recommendation is to finish drinking alcohol 2 to 3 hours before going to bed.\n\n",
      "down": "Your alcohol score has gone down %d points since June.<br>\nAlcohol is a kind of anesthetic. Even if its temporary effects help you fall asleep, you'll wake up after a certain period of time, resulting in a lower quality of sleep. At night, the recommendation is to finish drinking alcohol 2 to 3 hours before going to bed.\n\n",
      "equal": "Your alcohol score is the same as it was in June. <br>\nAlcohol is a kind of anesthetic. Even if its temporary effects help you fall asleep, you'll wake up after a certain period of time, resulting in a lower quality of sleep. At night, the recommendation is to finish drinking alcohol 2 to 3 hours before going to bed.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since June.<br>\nThe days when it's hard to sleep are dragging on, but are you making conscious choices about what to wear to bed? Wearing clothes made of breathable and easy-to-move-in materials such as cotton or linen will help you get a good night's sleep.\n\n",
      "down": "Your sleep score has gone down %d points since June.<br>\nThe days when it's hard to sleep are dragging on, but are you making conscious choices about what to wear to bed? Wearing clothes made of breathable and easy-to-move-in materials such as cotton or linen will help you get a good night's sleep.\n\n",
      "equal": "Your sleep score is the same as it was in June. <br>\nThe days when it's hard to sleep are dragging on, but are you making conscious choices about what to wear to bed? Wearing clothes made of breathable and easy-to-move-in materials such as cotton or linen will help you get a good night's sleep.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since June.<br>\nA temperature difference of 5 degrees or more between indoors and outdoors is said to increase the strain on the body. Check the temperature setting of your air conditioner frequently.\n\n",
      "down": "Your stress score has gone down %d points since June.<br>\nA temperature difference of 5 degrees or more between indoors and outdoors is said to increase the strain on the body. Check the temperature setting of your air conditioner frequently.\n\n",
      "equal": "Your stress score is the same as it was in June. <br>\nA temperature difference of 5 degrees or more between indoors and outdoors is said to increase the strain on the body. Check the temperature setting of your air conditioner frequently.\n\n"
    }
  }
}